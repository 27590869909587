export const termText = `
# 利用規約

## Ⅰ 総則

本利用規約（以下「本規約」といいます。）は、株式会社 Opening Line（以下「弊社」といいます。）が運営する本サイト（以下「第１条２項」に定義される「本サイト」をいいます。以下同じ。）において提供するファイル送受信・共有化ソリューションサービス（これらを総称して「本サービス等」といいます。以下同じ。）を、利用者（「Ⅱ 利用契約および利用」の規定により、弊社に本サービス等の利用契約を申し込み、弊社から認証情報を付与された利用者をいいます。以下同じ。）が利用するための、利用者と弊社との間の利用条件を定めたものです。本規約の各条項を必ずお読みいただき、ご同意いただいた場合のみ、本サービス等をご利用いただくことができるものとします。
本規約に付随して、本サービス等に応じて個別に策定されるガイドラインその他の細則等（以下「個別細則等」といいます。）が本規約と矛盾・抵触する場合、個別細則等が本規約に優先して、適用されるものとします。

### 第１条　定義

１．「本サービス等」とは、弊社が本サイトにおいて提供するファイル送受信・共有化ソリューションサービスの総称であり、これらの利用に付随するソフトウェア（以下「本ソフトウェア」といいます。）、および本サービス等がアップデートされた場合の該当アップデートサービス等を含みます。

２．「本サイト」とは、弊社が運営するクラウド型ファイル送受信・共有化ソリューションである「JUGGLE」をいいます。

### 第２条　知的財産権

本サービス等に係わる知的財産権は、弊社および弊社に権利を許諾した第三者に帰属します。本規約による本サービス等に係わる本ソフトウェアの利用者への使用許諾は、利用者に対する何らの権利移転等を意味するものではありません。

### 第３条　使用許諾

弊社は、利用者に対して、本サービス等に関する各種の機能を使用する目的のため、本サイトから提供する本ソフトウェアを本規約に従い使用することのできる、非独占的かつ譲渡不能の権利を許諾します。

### 第４条　使用条件

１．利用者は、本サービス等の利用に必要な環境の整備や維持管理を含み、本サービスのご利用にあたっては、諸法令を遵守し、全て利用者の責任において行うことを了承していただきます。

２．本サービス等のご利用には、別途インターネット環境、メール環境が必要となります。

### 第５条　禁止事項

１．弊社は、本サービス等において明示的に許諾されている場合を除き、利用者が本ソフトウェアを転載、複写、複製、転送、抽出、加工、改変、送信可能化し、その他一切の二次利用をすること、または本ソフトウェアを貸与、販売、再配布、公衆送信、再使用許諾等を行い、第三者に利用させることを禁止します。また、利用者は、本ソフトウェアを逆アセンブル、逆コンパイル、リバースエンジニアリング等により解析または改変を行うことはできません。

２．利用者は、本ソフトウェアもしくは本データに関する弊社もしくはその他の第三者の権利を侵害する行為、弊社もしくはその他の第三者に不利益や損害を与える行為、本サイトおよび本サービス等の運営を妨害する行為、またはそれらの恐れのある行為を一切行ってはならないものとします。

### 第６条　免責事項

１．弊社は、自己の責に帰し得ない事由によるものの他、以下に定める事項に起因して利用者または第三者が被った損害については、一切の賠償の責を免れるものとします。

（１）利用者が本規約に違反した場合

（２）本サイトへのアクセスまたは本サービス等の利用が過剰に集中した場合

（３）本サービス等を利用可能な機器以外で利用した場合

（４）本ソフトウェアが翻案・改変されたことに起因する場合

（５）他のデータやプログラムもしくは製品との組み合わせに起因する場合

（６）ウィルス感染またはデータサーバへの攻撃に起因する場合

（７）天災地変、その他不可抗力に起因する場合

（８）その他、利用者に不適切な行為または不作為があったと弊社が判断する場合

（９）その他、弊社の責に帰すべからざる事由による場合

２．弊社は、本サービス等で提供される全ての情報の正確性・完全性・特定の目的に対する適合性、本ソフトウェアの正常動作性もしくは機能性、本ソフトウェアおよび本データがウィルスに感染していないことを含み、本サービス等について、明示または黙示を問わず、一切の保証をしないものとします。弊社は、利用者が本ソフトウェアの使用を含む本サービス等の利用により得られた結果の正確性・完全性・特定の目的に対する適合性について、明示または黙示を問わず、一切の保証を行わず、当該結果の瑕疵又は不具合等について一切の責任を負わないものとします。

３．弊社は、明示的または黙示的を問わず、本ソフトウェアが第三者の知的財産権を侵害していないことについて、一切の保証をしないものとします。

４．本サービス等に係る通信の通信環境の悪化その他の不具合により利用者が本サービス等を利用できない場合、弊社は、利用者に生じたいかなる損害についても一切の責任を負わないものとします。

５．弊社および弊社の役職員は、いかなる場合であっても、利用者が被った拡大損害、間接損害、特別損害または付随損害については責任を負わないものとします。

６．前５項に定めるものの他、弊社の故意または重過失による場合を除き、利用者が、本サービス等をご利用できないこと、または本サービス等の利用に関して損害（利用者が保存したデータの毀損もしくは滅失または第三者への漏洩による損害、営業上の利益の逸失による損害を含み、これらに限られません。）を被ったことについて、弊社および弊社の役職員は一切の責任を負わないものとします。また、弊社の故意または重過失により利用者に損害が生じた場合、弊社は、通常生じうる予見可能な損害の範囲内で、かつ、利用者が支払った有料サービスの利用料額（継続的なサービスの場合は１ヶ月分相当額）を上限として、損害賠償責任を負うものとします。

### 第７条　本サービス等の変更・中断・中止

１．弊社は、本サービス等の内容および提供方法を予告なく弊社の裁量により機能改良、追加、中止、変更、削除またはアクセス不能とすることができ、当該機能改良、追加、中止、変更、削除またはアクセス不能となったことにより利用者が損害を被ったとしても、弊社は一切賠償の責を負わないものとします。

２．弊社は、本サービス等の提供に必要な設備等の定期的もしくは緊急的な保守、工事もしくは障害の対策等が必要な場合、事故、天災、不可抗力、電気通信事業者による電気通信役務の提供の中止等、弊社の責によらない事由により本サービス等の提供が不可能となった場合、または運用上・技術上やむを得ないと弊社が判断した場合、事前に利用者に通知することなく、本サービス等の提供の全部または一部を停止する場合があります。なお、この場合、弊社は利用者に生じたいかなる損害についても、一切賠償の責を負わないものとします。

### 第８条　個人情報の取扱

１．弊社は、個人情報保護法その他関連法令を遵守し、利用者が本サービス等を利用するにあたり、弊社が入手した利用者の個人情報は、別途弊社の定める「プライバシーポリシー」に従い取り扱います。

２．弊社は、利用者より入手した個人情報を以下の目的のために利用するものとします。

（１）本サービス等の運営および各種手続のため

（２）本サービス等に関する通知または本サービス等の保守・変更・障害対応のため

（３）利用者からのご意見・ご感想をいただくため

（４）利用者からのお問い合わせや資料請求などに対応するため

（５）市場調査や新しい商品・サービスの開発のため

（６）各種イベント・セミナー・キャンペーン・会員制サービスなどの案内のため

（７）電子メール配信サービスや刊行物などの発送のため

（８）弊社または提携先で取り扱っている商品やサービスに関する情報の提供のため

（９）有料サービスに係わる料金のご請求・決済のため

（10）会計監査上の確認作業のため

（11）その他弊社の事業に付帯・関連する事項のため

### 第９条　注意事項

弊社は、本サービス等で提供されまたは利用者により本サイトに送信され、もしくは弊社のデータサーバに格納されたデータのバックアップを行わず、データ消失時のデータ復旧作業を行いません。本サービス等に関連するデータの消失に関して、弊社は一切の責任を負わないものとします。

### 第 10 条　規約の変更

弊社は、利用者への事前の通知、承諾なくして本規約の全部または一部を変更することができるものとします。なお、変更後の本規約は、本サイトに掲示したときにその効力を生ずるものとします。この場合、利用者は、変更後の本規約に従うものとします。

### 第 11 条　解除

弊社は、利用者が本規約に違反しまたは違反する恐れのある場合は、何ら通知催告を要せず、利用者に対する本サービス等の提供を停止もしくは終了することができるものとします。なお、これにより、弊社に損害が生じた場合、弊社は利用者に対して損害賠償の請求を行うことができるものとします。

## 第 12 条　裁判管轄

本規約に関し、利用者と弊社との間で疑義または争いが生じた場合には、誠意を持って協議し、解決することとしますが、それでもなお解決しない場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

### 第 13 条　一般条項

１．弊社から利用者に対する通知・告知は、本サイト上での掲示や電子メールの送付等の発信、その他弊社が適当と判断する方法により行います。

２．前項の通知・告知について、本サイト上での掲示や電子メールの送信で行った場合は、当該通知・告知事項の弊社による掲示や送信行為が行われた時点より、その効力を発するものとします。

## Ⅱ 利用契約および利用

「Ⅱ 利用契約および利用」のパート（以下「本パート」といいます。）は、利用者が弊社の提供する本サービス等を利用するにあたり必要な利用契約とその利用の際の留意事項等について、特に定めるものです。

### 第 14 条　申込み

利用者は、本規約の内容に同意し、弊社の定める手続・方法に従って、本サービス等の利用契約に申し込むものとします。

### 第 15 条　承諾と承諾の取消

１．弊社は、利用者が本サービス等の利用契約を申し込まれた場合、本サービス等を利用される利用者認証を行うために必要な情報（以下「認証情報」といいます。）を利用者に貸与し、または特定します。この貸与・特定をもって申込みに対する承諾とみなし、同時に、利用者と弊社との間の本サービス等の利用契約が成立したものとします。

２．弊社は、本サービス等の利用契約を申し込まれた利用者または本サービス等を利用されている利用者が、以下に定める事項に該当したときは、前項の規定にかかわらず、承諾を行わず、または、既に行った認証情報の貸与・特定、承諾を取り消すことができるものとします。

（１）弊社に申し出られた登録情報に虚偽が含まれていた場合

（２）「第５条」に規定する禁止事項に該当した場合

（３）業務遂行上または技術上、利用者による本サービス等の利用が適当でないと弊社が判断した場合

### 第 16 条　留意事項

１．利用者は、自らの責任をもって、認証情報の使用および管理（パスワードの強化を含みます。）を行います。

２．弊社は、利用者に貸与した認証情報の再発行を、その理由の如何を問わず、原則として行わないものとします。ただし、弊社が別途定めた手続・方法により再発行の申請を行った場合は、この限りではありません。

３．弊社は、利用者が本パートにて貸与・特定を受けた認証情報を第三者に貸与して利用させること、第三者に貸与された認証情報を借り受けて使用すること、およびその他の不正使用（以下これらを総称して、本パートにおいて「不正使用等」といいます。）をすることを禁止します。

４．利用者は、不正使用等を発見した場合、速やかに弊社へ連絡するものとし、弊社から指示がある場合は、これに従うものとします。

５．認証情報の使用上もしくは管理上の過誤または不正使用等により利用者に生じた損害や損失等は、利用者が自ら負担するものとします。弊社は、当該過誤または不正使用等に関する一切の責任を負いません。

6．全ユーザ合計ファイル容量が「契約ユーザ数 ×5GB/月」を超過する場合」ファイル容量を大きくするオプション契約を月の途中で追加申請できるものとする。ファイル容量の追加申請がない場合は規定の容量に達した段階で、それ以上の新規ファイルの共有サービスの利用をできないものとします。

### 第 17 条　利用停止・終了

１．利用停止
弊社は、利用者が本パートの「第 16 条 留意事項」に違反した場合には、利用者による本サービス等の利用を停止することができます。

２．利用終了
以下に定めるいずれかに該当する場合には、利用者による本サービス等の利用は終了します。

（１）利用者は、弊社の定める手続・方法に従い、利用者の任意によって、本サービス等の利用を終了することができます。

（２）弊社は、利用者の行為が「第５条 禁止事項」の１つにでも該当した場合で、相当の期間を定めて是正を求めたにもかかわらず是正がなされないときは、当該利用者による本サービス等の利用を終了させることができます。

（３）弊社は、利用者の行為が「第５条 禁止事項」の１つにでも該当した場合で、過去にも当該事由に該当する行為を行った経歴があり、または、当該事由に該当したことによる違反の程度が重大であるなど、即座の終了が適当であると弊社が判断したときは、前項の規定にかかわらず、即座に、当該利用者による本サービス等の利用を終了させることができます。

### 第 18 条　業務委託

１．弊社は、本サービス等の利用契約の締結およびこれに関連する業務（利用者からのお問い合わせ対応、利用者への本サービス等のご案内・ご提案を含みますが、これらに限られません。）並びに利用料金の回収業務の全部または一部を、第三者に対して委託することができます。

２．前項の業務委託に際して、弊社が第三者に利用者の個人情報を預託する場合、当該業務委託の範囲内での利用に限定して預託するものとし、その個人情報の取扱については、別途弊社の定める「プライバシーポリシー」によるものとします。

### 第 19 条　利用者データの取扱

１．弊社は、利用者が本サービス等を利用するに際して、弊社のデータサーバに複製された利用者のデータ（以下「利用者データ」といいます。）を第三者に開示せず、第三者への開示を防止するために弊社が合理的と考える措置を講じるものとします。なお、弊社は、第三者による利用者データの不正な取得及び使用について一切の責任を負いません。

２．弊社は、本サービス等の提供、その他の利用者からの依頼への対応の目的の範囲を超えて、利用者データを使用しないものとします。

３．前２項の規定にかかわらず、弊社は、法令、官公署の命令その他これらに準ずる定めに基づき開示を要求された場合には、利用者データを必要な限度において開示することができるものとします。ただし、弊社は、かかる開示を要求された場合には、法令上の義務に反しない限り、遅滞なく、事前にその旨を利用者に通知するものとします。

４．第１項の規定にかかわらず、弊社は、弊社が本サービス等の運営にあたり業務を委託する委託先に対して、当該業務委託のために必要な範囲で、利用者から事前の承諾を受けることなく利用者データを提供できるものとします。ただし、この場合、弊社は、個人情報の取扱については別途弊社の定める「プライバシーポリシー」によるものとし、また、委託先に対して、本条に基づき弊社が負う義務と同等の義務を負わせるものとします。

５．弊社は、利用者データが本社の業務または本サイトもしくは本サービス等の運営に悪影響を及ぼすと判断する場合、弊社の裁量により利用者データを弊社のデータサーバから削除することができるものとします。

６．弊社は、利用者データのダウンロード期限が経過した場合、または利用者の本サービス等の利用が終了した後は、利用者データを保管する義務を負わず、利用者データを消去するものとします。

### 第 20 条　有料サービス

１．本サービス等は、弊社が有料で利用者に提供するサービス（以下「有料サービス」といいます。）であり、その料金については、弊社が利用者に提供する料金表に記載するものとします。

２．利用者は、有料サービスを利用する場合、前項に従い料金を確認、承諾した上で当該サービスの申込みを行うものとします。

### 第 21 条　有料サービス利用および解約手続

１．利用者は有料サービスを利用するにあたり、料金表の中からいずれかのプランを選択するものとします。また、利用者がプラン変更手続を行った場合、変更後のプランへの切替は翌月 1 日から適用されます。

２．有料サービスの利用は１ヶ月単位としますが、次項に定める解約手続が行われない限り、原則として１ヶ月単位で自動更新されます。

３．利用者が有料サービスを解約する場合、別途弊社が定める解約手続により行うものとします。なお、利用者による解約は、月末を解約日とし、解約日の 2 カ月前までに解約手続を行うものとします。

### 第 22 条　利用料等

１．有料サービスの利用料は、弊社が利用者に対して毎月第 5 営業日までに前月分の利用状況に基づく請求書を発行し、利用者は当該請求書にかかる利用料を毎月 15 日までに（15 日が休日の場合は翌営業日までに）弊社が指定する口座に振り込む方法により支払うものとします。なお、振込手数料は利用者の負担とします。

２．弊社は、利用者が支払った利用料については、いかなる理由においても利用者に返還（日割り計算による一部返金を含みます。）する義務を負わないものとします。

３．弊社は、利用者が利用料の支払いを怠った場合には、何ら通知催告を要せず、利用者による本サービス等の利用を停止または終了させることができるものとします。

４．有料サービスの利用料は、月途中の利用開始および月途中の解約については日割り計算は行わないものとします。1 日から月末までの利用料を請求させていただくものとします。

### 第 23 条　料金の変更

弊社が必要と判断した場合には、有料サービスの利用料を変更する可能性があります。その場合、新料金が適用される初日までの２ヶ月以上の予告期間をもって、本サイト上での告知、その他弊社が適当と認める方法により告知するものとします。利用者が当該有料サービスを解約しない限り、変更の告知において定める新料金適用日から当該変更の効力が生じ、利用者は当該変更を承諾したものとします。

以上`