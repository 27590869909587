import * as React from "react"
import Seo from "../components/seo"
import { useRemark } from 'react-remark'
import styled from '@emotion/styled'
import Layout from "../components/layout"
import Title from '../components/feature/Contact/Title'
import { termText } from "../datas/juggle"
import { Color } from "../util/Color"

const description = ''

const Privacy = () => {
  const [reactContent, setMarkdownSource] = useRemark();

  React.useEffect(() => {
    setMarkdownSource(termText);
  }, []);
  return (
    <Layout>
      <Seo title="Juggle利用規約" description={description}/>
      <Title en="Juggle Terms" ja="Juggle利用規約" />
      <Content>
        {reactContent}
      </Content>
    </Layout>
    )
}

export default Privacy

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  '> *': {
    fontWeight: 400,
    fontFeatureSettings: '"palt" 1',
    fontFamily: 'Noto Sans JP',
    color: Color.black
  }
})
